import axios from "axios";


function getAPIReport() {
    const reportApi = axios.create({
        baseURL: `${import.meta.env.VITE_REPORT_API_URL}`,
        headers: {
            "x-token-key": `${import.meta.env.VITE_REPORT_TOKEN}`,
            "Content-Type": "application/json"
        }
    })
    reportApi.interceptors.request.use(
        (config) => {
          const newConfig = config;
          newConfig.headers.post['x-token-key'] = import.meta.env.VITE_REPORT_TOKEN;
          return newConfig;
        },
        (error) => Promise.reject(error)
      );
    return reportApi;

}
export default getAPIReport;
