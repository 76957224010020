import { RedeemProps } from "@/models";
import { Api } from "@/services";
import { filterAndPageableToRequest, formatCurrency, showToast } from "@/utils";
import { useState } from "react";

export function useRedeem() {
  const baseUrl = "/redeems";
  const fraudBaseUrl = "/sellers/anti-fraud";
  const [initialData, setInitialData] = useState({} as RedeemProps);
  const getReedemAmount = () => {
    return Api.get(baseUrl);
  }
  const getRedeems = (filter: object, pageable: object) => {
    return Api.get(
      `${baseUrl}/statement${filterAndPageableToRequest({ filter, pageable })}`
    );
  };

  const getRedeemCSV = (filter: object, pageable: object) => {
    return Api.get(
      `${baseUrl}/statement/csv${filterAndPageableToRequest({
        filter,
        pageable,
      })}`
    );
  };

  const getSellerAnticipationAmount = (data) => {
    return Api.post(
      `${baseUrl}/anticipation/amount`, data
    )
  }

  const getSellerFraud = (id) => {
    return Api.get(`${fraudBaseUrl}`);
  }

  async function getInitialData(filter: object, pageable: object, isShortRedeemData?: boolean) {
    try {
      let initialDataObject;
      if (isShortRedeemData) {
        const amount_response: any = await getReedemAmount();
        const formatAmountToCurrency = formatCurrency({ value: amount_response.data.amount })
        const formatProvisionedAmountToCurrency = formatCurrency({ value: amount_response.data.provisionedAmount })
        const formatTotalAmountValue = formatCurrency({ value: amount_response.data.amount + amount_response.data.provisionedAmount }).split("R$")
        initialDataObject = {
          ...initialDataObject,
          amount: formatAmountToCurrency,
          notFormattedAmount: amount_response.data.amount,
          provisionedAmount: formatProvisionedAmountToCurrency,
          amountToAnticipation: formatCurrency({ value: amount_response.data.amountToAnticipation }),

          totalAmount: formatTotalAmountValue,
        }
        setInitialData(initialDataObject);
        return
      }
      const redeems_response = await getRedeems(filter, pageable);
      const amount_response: any = await getReedemAmount();
      const formatAmountToCurrency = formatCurrency({ value: amount_response.data.amount })
      const formatProvisionedAmountToCurrency = formatCurrency({ value: amount_response.data.provisionedAmount })
      const formatTotalAmountValue = formatCurrency({ value: amount_response.data.amount + amount_response.data.provisionedAmount }).split("R$")
      initialDataObject = {
        ...initialDataObject,
        amount: formatAmountToCurrency,
        provisionedAmount: formatProvisionedAmountToCurrency,
        totalAmount: formatTotalAmountValue,
        amountToAnticipation: formatCurrency({ value: amount_response.data.amountToAnticipation }),
        ...redeems_response.data
      }
      setInitialData(initialDataObject);
    } catch (error) {
      showToast({
        type: "error",
        message: "Falha no carregamento dos repasses.",
      });
    }
  }

  return {
    initialData,
    getRedeems,
    getRedeemCSV,
    getInitialData,
    getReedemAmount,
    getSellerAnticipationAmount,
    getSellerFraud
  };
}
