import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Flex, Button, Center, Divider } from "@chakra-ui/react";
import * as yup from "yup";
import { add, format, formatISO } from "date-fns";
import { PaymentMethod } from "../../../layouts/SejaPago/PaymentMethod";
import { formatCurrency, formatCurrencyToApi, formatStringAmountToApi, getApplicationName, showToast } from "@/utils";
import {
  Input,
  InputCurrency,
  Select,
  TextArea,
  Alert,
  InputSearch,
  AccordionTextArea,
} from "@/components";
import {
  ClientData,
  PaymentMethodTest,
  PunctualChargeProps,
  PaymentLinkSubSellerData,
  SubSellerTaxResponse
} from "@/models";
import { useCustomLink } from "@/hooks";
import { formatPercentage } from "@/utils/formatPercentage";
import { formatPercentageToApi } from "@/utils/formatPercentageToApi";
import { TimeDatePickerInput } from "@/components/Forms/TimeDatePickerInput";
import { CustomFields } from "@/components/Forms/CustomFields";
import { NewContainer } from "@/layouts/SejaPago/NewContainer";
import { SubSellerModal } from "@/components/SubSellersModal";
import { PaymentSplit } from "@/layouts/SejaPago/PaymentSplit";


const paymentMethods: Array<PaymentMethodTest> = [
  { tagName: "creditCard", name: "Cartão de Crédito", reg: "enableCreditCard" },
  { tagName: "bankSlip", name: "Boleto", reg: "enableBankSlip" },
  { tagName: "pix", name: "PIX", reg: "enablePix" },
];

const paymentMethodsLumini: Array<PaymentMethodTest> = [
  { tagName: "creditCard", name: "Cartão de Crédito", reg: "enableCreditCard" },
];

function isFutureDate(dateStr: any): boolean {
  const inputDate = new Date(dateStr);
  const currentDate = new Date();

  return inputDate > currentDate;
}

export interface subSellerData {
  idSubSeller: number;
  id?: number;
  name: string;
  documentType?: string;
  document?: string;
  sellerId?: number;
  isActive?: boolean;
  openingDate?: string;
  paymentType: string;
  paymentValue: number;
  defaultPaymentValue: number;
  defaultPaymentType: string;
  amount?: number;
  description?: string;
  amountOnPaymentLink?: number;
  address?: {
    id: number;
    country: string;
    state: string;
    city: string;
    district: string;
    street: string;
    zipCode: string;
    number: string;
    complement: string;
  };
  account?: {
    id?: number;
    bank?: number;
    bankDigit: string;
    issuer?: string;
    issuerDigit: string;
    number?: string;
    pixKey: string;
    pixKeyType: string;
  };
  contact?: {
    id: number;
    countryCode: string;
    name: string;
    email: string;
    phone: string;
  };
  owner?: {
    ownerName: string;
    ownerDocument: string;
    birthdate?: string;
  };
}

export function PunctualCharge({
  asEdition,
  initialData,
  punctualButton,
  setPunctualButton,
  setPreviewData,
  previewData,
}: PunctualChargeProps) {
  const [expirationSwitchState, setExpirationSwitchState] = useState(false);
  const [timerState, setTimerState] = useState(false);
  const [dateSwitchState, setDateSwitchState] = useState(false);

  const [anchoringState, setAnchoringState] = useState(
    initialData?.enableAnchoring ? initialData?.enableAnchoring : false
  );
  const [loading, setLoading] = useState(false);
  const [confirmModalState, setConfirmModalState] = useState(false);
  const [url, setUrl] = useState("");
  const [clientList, setClientList] = useState<Array<ClientData>>();
  const [selectedClient, setSelectedClient] = useState<ClientData>();
  const [deleteModalState, setDeleteModalState] = useState(false);
  const [metadados, setMetadados] = useState([]);
  const viewMetadados = localStorage?.getItem("barte-features-list-user")?.includes("view_payment_link_metadata")
  const [uuid, setUuid] = useState(
    window.location.search.split("?id=").join("")
  );



  const [isOk, setIsOk] = useState(
    {
      geralInformation: false,
      paymentMethods: false,
    }
  );


  function handleChange(name: string, value: any) {

    setValue(name, value)
    setPreviewData((preview) => ({
      ...preview, [name]: value
    }))
  }




  const options = [
    { title: "Pontual", name: "Pontual", value: "Pontual" },
    { title: "Recorrente", name: "Recorrente", value: "Recorrente" }]

  const PaymentOrderSchema = yup
    .object()
    .shape({
      title: yup.string().required("Preenchimento obrigatório!").max(60, 'O título deve ter no máximo 60 caracteres!'),
      value: yup.string().required("Preenchimento obrigatório!"),
      installments: yup.number(),
      date: yup.date(),
      bankSlipDescription: yup.string().max(130, 'A descrição do boleto deve ter no máximo 130 caracteres!'),
      timer: yup.date().test("timer", "A data precisa estar no futuro", (value) => {
        if (!expirationSwitchState) {
          return true;
        }
        return isFutureDate(value);
      })
    })
    .test("paymentMethodsValidation", null, (obj) => {
      if (obj.enableBankSlip || obj.enableCreditCard || obj.enablePix) {
        return true;
      }
      return new yup.ValidationError(
        "Selecione ao menos um método de pagamento.",
        null,
        "paymentMethods"
      );
    });

  const {
    postCustomLink,
    handleDeleteCustomLink,
    getCustomLinkData,
    putCustomLink,
    getSplitValue,
  } = useCustomLink();
  const navigate = useNavigate();
  const cancelRef = useRef();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
    watch,
    getValues
  } = useForm({
    resolver: yupResolver(PaymentOrderSchema),
  });
  const bankSlipDescriptionValue = watch("bankSlipDescription");
  const optionalDescription = watch("description")
  const title = watch("title")
  const value = watch("value")
  const enableCreditCard = watch("enableCreditCard")
  const enableCreditCardPercent = watch("enableCreditCardPercent")
  const customInstallmentsValuesCreditCard = watch("customInstallmentsValuesCreditCard")
  const enablePix = watch("enablePix")
  const enablePixPercent = watch("enablePixPercent")
  const customInstallmentsValuesPix = watch("customInstallmentsValuesPix")
  const enableBankSlip = watch("enableBankSlip")
  const scheduleDate = watch("date")
  const enableBankSlipPercent = watch("enableBankSlipPercent")
  const customInstallmentsValuesBankSlip = watch("customInstallmentsValuesBankSlip")
  const ExpireParsedTimer = watch("timer")
  const [splitModalSubseller, setSplitModalSubseller] = useState<boolean>(false);
  const [subSellersData, setSubSellersData] = useState<Array<subSellerData>>([] as Array<subSellerData>);
  const viewSplit = localStorage?.getItem("barte-features-list-user")?.includes("view_split_subseller_payment_link") && watch("enableSplit")
  const [subSellerTaxResponse, setSubSellerTaxResponse] = useState<SubSellerTaxResponse>();
  const [split, setSplit] = useState(initialData?.subSellerResponse?.length >= 1)
  const values = getValues()
  const [metadadosDisabled, setMetadadosDisabled] = useState<boolean>(false)
  const [bankSlipDescriptionState, setBankSlipDescriptionState] = useState(false);
  const descriptionValue = watch("description");
  const hasTagMix = localStorage?.getItem("hasMixInstallments") === "true" ? true : false;
  const user = JSON.parse(localStorage.getItem("barte-user-info"));
  const [creditCardType, setCreditCardType] = useState(
    initialData?.allowedPaymentMethods?.creditCardMethod?.type && asEdition
      ? initialData?.allowedPaymentMethods?.creditCardMethod?.type
      : (user?.idSeller === 4350 || user?.idSeller === 4483 || user?.idSeller === 4768 || user?.idSeller === 4770) ? "EARLY_SELLER" : "EARLY"
  );

  function filterCustomInstallments(values: any): Array<any> {
    const filteredCustomInstallments = [
      values.customInstallmentsValuesCreditCard && {
        paymentMethod: hasTagMix ? "CREDIT_CARD_EARLY_MIXED" : creditCardType === "RECURRENT" ? "CREDIT_CARD" : `CREDIT_CARD_${creditCardType}`,
        installments: values.customInstallmentsValuesCreditCard
      },
      values.customInstallmentsValuesBankSlip && {
        paymentMethod: "BANK_SLIP",
        installments: values.customInstallmentsValuesBankSlip
      },
      values.customInstallmentsValuesPix && {
        paymentMethod: "PIX",
        installments: values.customInstallmentsValuesPix
      },
    ].filter(item => item && item.installments !== 0)
    return filteredCustomInstallments;
  }
  async function handleSplitValues() {
    try {
      const splitBodyRequest: Array<PaymentLinkSubSellerData> = subSellersData.map(item => {
        //@ts-ignore
        let paymentValue = item?.paymentValue;
        return {
          idSubSeller: item?.id || item?.idSubSeller,
          amount: formatCurrencyToApi(watch("value")),
          paymentType: item?.paymentType,
          paymentValue: item?.paymentType === "PERCENTAGE" ? formatPercentageToApi(paymentValue) : formatStringAmountToApi(paymentValue),
        }
      })
      const { data } = await getSplitValue(splitBodyRequest);
      data?.subSellerPaymentResponse?.map(subSRes => {
        const getSubSellerIndex = subSellersData.findIndex(subData => subData?.id === subSRes?.idSubSeller);
        if (getSubSellerIndex !== -1) {
          subSellersData[getSubSellerIndex].amountOnPaymentLink = subSRes?.amountOnPaymentLink;
        }
      })
      //@ts-ignore
      setSubSellerTaxResponse(data);
    } catch (error) {
      console.log("response:", error);
      error?.response?.data?.errors?.map((error) => {
        showToast({
          type: "error",
          message: `${error?.code} - ${error?.description}`
        })
      });
    }
  }

  function handleSetOrderValues(data: any) {
    if (data?.scheduledDate) {
      setDateSwitchState(true);
      setValue("date", add(new Date(data?.scheduledDate), { days: 1 }));
    }

    setValue("title", data?.paymentOrder?.title);
    setValue("description", data?.paymentOrder?.description);
    if (data?.bankSlipDescription?.length > 0) {
      setBankSlipDescriptionState(true);
      setValue("bankSlipDescription", data?.bankSlipDescription);
    }
    setValue(
      "value",
      formatCurrency({ value: Number(data?.paymentOrder?.value) }) || ""
    );
    setValue("installments", data?.paymentOrder?.installments);
    setValue("enableBankSlip", data?.allowedPaymentMethods?.bankSlipMethod);
    setValue("enableCreditCard", data?.allowedPaymentMethods?.creditCardMethod);
    setValue("enablePix", data?.allowedPaymentMethods?.pixMethod);
    setValue(
      "enableCreditCardPercent",
      data?.allowedPaymentMethods?.creditCardMethod?.discount?.amount ?
        formatPercentage({ value: data?.allowedPaymentMethods?.creditCardMethod?.discount?.amount }) :
        "0"
    );
    setValue(
      "enableBankSlipPercent",
      data?.allowedPaymentMethods?.bankSlipMethod?.discount?.amount ?
        formatPercentage({ value: data?.allowedPaymentMethods?.bankSlipMethod?.discount?.amount }) :
        "0"
    );
    setValue(
      "enablePixPercent",
      data?.allowedPaymentMethods?.pixMethod?.discount?.amount ?
        formatPercentage({ value: data?.allowedPaymentMethods?.pixMethod?.discount?.amount }) :
        "0"
    );
    setValue(
      "timer",
      data?.paymentOrder?.expirationDate ? new Date(data?.paymentOrder?.expirationDate) : new Date()
    );
    setExpirationSwitchState(!!data?.paymentOrder?.expirationDate);
    setTimerState(data?.paymentOrder?.timer);
    setUrl(data.url);
    setAnchoringState(data?.enableAnchoring);
    const formattedSubSellerData = initialData?.subSellerResponse?.map(subS => {
      return {
        ...subS,
        defaultPaymentValue: subS.paymentValue,
        defaultPaymentType: subS.paymentType,
      }
    })
    setSubSellersData(formattedSubSellerData);
    setUuid(data.uuid);
    data?.paymentOrder?.customInstallmentsValues?.map(item => {
      if (item?.paymentMethod?.includes("CREDIT_CARD")) {
        setValue("customInstallmentsValuesCreditCard", item?.installments)
      }
      if (item?.paymentMethod?.includes("BANK_SLIP")) {
        setValue("customInstallmentsValuesBankSlip", item?.installments)
      }
      if (item?.paymentMethod?.includes("PIX")) {
        setValue("customInstallmentsValuesPix", item?.installments)
      }
    });
    if (initialData?.subSellerResponse?.length >= 1) {
      setSplit(true)
    }
  }
  async function handleGetDataToDuplicate(uuid: string) {
    try {
      const { data } = await getCustomLinkData(uuid);
      handleSetOrderValues(data);
    } catch (error) {

      error?.response?.data?.errors.map((error) => {
        showToast({
          type: "error",
          message: `${error?.code} - ${error?.description}`
        })
      });
    }
  }

  async function getInitialData() {
    setValue("date", new Date());
    if (initialData) return handleSetOrderValues(initialData);
    if (uuid) {
      try {
        handleGetDataToDuplicate(uuid);
        return;
      } catch (error) {
        error?.response?.data?.errors.map((error) => {
          showToast({
            type: "error",
            message: `${error?.code} - ${error?.description}`
          })
        });
      }
    }
    if(user?.idSeller === 4483) {
      setValue("installments", 6)
    } else {
      setValue("installments", 12)
    } 
  }

  const handleCreatePaymentOrder: SubmitHandler<any> = async (
    values
  ) => {
    setLoading(true);
    const parsedDate =
      dateSwitchState && formatISO(values?.date, { representation: "date" });
    const parsedTimer = expirationSwitchState && formatISO(values?.timer, { representation: "complete" }).split("-03:00").at(0);
    const formattedValue = formatCurrencyToApi(values?.value);
    const subSellersDataFilter = subSellersData?.map((item) => ({
      idSubSeller: item?.id || item?.idSubSeller,
      paymentType: item?.paymentType,
      paymentValue: item?.paymentValue,
      amount: item?.amountOnPaymentLink
    })).filter(sub => sub?.idSubSeller !== undefined);
    const applicationName = getApplicationName({});
    const paymentOrderNewDTO = {
      type: "ORDER",
      whiteLabel: applicationName !== "barte" ? true : false,
      scheduledDate: dateSwitchState ? parsedDate : null,
      uuidSellerClient: selectedClient?.uuid,
      bankSlipDescription: (bankSlipDescriptionState && values?.bankSlipDescription?.length > 0) ? values?.bankSlipDescription : null,
      metadata: metadadosDisabled ? metadados : null,
      subSellerPaymentRequest: split ? subSellersDataFilter : [],
      paymentOrder: {
        title: values?.title,
        description: values?.description,
        installments: 10,
        value: formattedValue,
        customInstallmentsValues: filterCustomInstallments(values),
        expirationDate: parsedTimer ? parsedTimer : null,
        timer: parsedTimer ? timerState : null

      },
      enableAnchoring: anchoringState,
      allowedPaymentMethods: {
        creditCardMethod: values.enableCreditCard
          ? {
            type: hasTagMix ? "EARLY_MIXED" : creditCardType,
            discount: (values.enableCreditCardPercent && formatPercentageToApi(values?.enableCreditCardPercent) > 0)
              ? {
                amount: formatPercentageToApi(values?.enableCreditCardPercent),
                type: "PERCENT",
              }
              : null,
          }
          : null,
        bankSlipMethod: values.enableBankSlip
          ? {
            discount: (values.enableBankSlipPercent  && formatPercentageToApi(values.enableBankSlipPercent) > 0)
              ? {
                amount: formatPercentageToApi(values.enableBankSlipPercent),
                type: "PERCENT",
              }
              : null,
          }
          : null,
        pixMethod: values.enablePix
          ? {
            discount: (values.enablePixPercent  && formatPercentageToApi(values.enablePixPercent) > 0)
              ? {
                amount: formatPercentageToApi(values.enablePixPercent),
                type: "PERCENT",
              }
              : null,
          }
          : null,
      }
    };

    try {
      if (asEdition) {
        const { data } = await putCustomLink(
          initialData?.uuid,
          paymentOrderNewDTO
        );
        setUrl(`${import.meta.env.VITE_CHECKOUT_URL_CHECKOUTV2}payment-link/${initialData?.uuid}`)
      } else {
        const { data } = await postCustomLink(paymentOrderNewDTO);
        setUrl(`${import.meta.env.VITE_CHECKOUT_URL_CHECKOUTV2}payment-link/${data?.uuid}`)
      }

      setConfirmModalState(!confirmModalState);
    } catch (error) {
      error?.response?.data?.errors?.map((error) => {
        showToast({
          type: "error",
          message: `${error?.code} - ${error?.description}`
        })
      });
    }
    setLoading(false);
  };

  async function handleDelete() {
    try {
      const response = await handleDeleteCustomLink(uuid);
      showToast({
        message: "Link customizado excluído com sucesso!",
        type: "success",
      });
      setDeleteModalState(!deleteModalState);
      navigate("/seja-pago");
    } catch (error) {
      error?.response?.data?.errors.map((error) => {
        showToast({
          type: "error",
          message: `${error?.code} - ${error?.description}`
        })
      });
    }
  }

  useEffect(() => {
    getInitialData();
  }, []);



  useEffect(() => {


    if (value !== "" && title !== "") {
      setIsOk(() => ({
        ...isOk,
        geralInformation: true
      }));
    }
    if ((value === "" || title === "")) {
      setIsOk(() => ({
        ...isOk,
        geralInformation: false
      }));
    }

    if ((enableBankSlip === true || enablePix === true)) {
      setSplit(false);
    }

    if (
      previewData?.enablePix === true ||
      previewData?.enableCreditCard === true ||
      previewData?.enableBankSlip === true
    ) {
      setIsOk({
        ...isOk,
        paymentMethods: true
      })
    } if (
      previewData?.enablePix === false &&
      previewData?.enableCreditCard === false &&
      previewData?.enableBankSlip === false
    ) {
      setIsOk({
        ...isOk,
        paymentMethods: false
      })
    }
  }, [previewData]);



  useEffect(() => {

    const updatedGeralInformation = {
      ...previewData,
      title: title,
      custonFields: { metadados },
      cliente: selectedClient?.name,
      hasDescription: bankSlipDescriptionState,
      description: optionalDescription,
      value: value,
      subSellersData: subSellersData,
      enablePix: enablePix,
      enableCreditCard: enableCreditCard,
      enableBankSlip: enableBankSlip,
      boleto: {
        discount: enableBankSlipPercent ?? '0%',
        installments: customInstallmentsValuesBankSlip,

      },
      creditcard: {
        discount: enableCreditCardPercent ?? '0%',
        scheduleDate: dateSwitchState ? format(scheduleDate, 'dd/MM/yyyy') : "",
        tax: creditCardType?.tax,
        installments: customInstallmentsValuesCreditCard,
      },
      pix: {
        discount: enablePixPercent ?? '0%',
        tax: customInstallmentsValuesPix,
        installments: customInstallmentsValuesPix,

      },
      expireDate: expirationSwitchState ? format(ExpireParsedTimer, 'dd/MM/yyyy HH:mm:ss') : ""
    };
    setPreviewData(updatedGeralInformation);

  }, [metadados, selectedClient, bankSlipDescriptionValue, optionalDescription,
    title, value, enableCreditCard, enableCreditCardPercent,
    customInstallmentsValuesCreditCard, enablePix, enablePixPercent, customInstallmentsValuesPix,
    enableBankSlip, customInstallmentsValuesBankSlip, ExpireParsedTimer, creditCardType, scheduleDate,
    subSellersData, expirationSwitchState, dateSwitchState, bankSlipDescriptionState, enableBankSlipPercent
  ]);



  useEffect(() => {
    if (split === true) {
      setValue("enableBankSlip", false);
      setValue("enablePix", false);
      setValue('enableCreditCard', true);
    }
  }, [split])



  useEffect(() => {
    setPreviewData({
      title: '',
      custonFields: {},
      cliente: '',
      hasDescription: false,
      description: '',
      value: '',
      enablePix: '',
      enableCreditCard: '',
      enableBankSlip: '',
      boleto: {
        discount: '',
        installments: '',

      },
      creditcard: {
        discount: '',
        scheduleDate: '',
        tax: '',
        installments: '',
      },
      pix: {
        discount: '',
        tax: '',
        installments: '',

      },
      expireDate: ''
    })
  }, [punctualButton])

  return (
    <>
      <Alert
        isConfirmAlert={true}
        isOpen={confirmModalState}
        onConfirm={() => (
          setConfirmModalState(!confirmModalState), navigate("/seja-pago")
        )}
        onClose={() => setConfirmModalState(!confirmModalState)}
        title="Cobrança criada com sucesso"
        messageBody={`Clique no link abaixo para copiar:`}
        urlBody={url}
        leastDestructiveRef={cancelRef}
      />
      <Alert
        isConfirmAlert={false}
        isOpen={deleteModalState}
        onConfirm={handleDelete}
        onClose={() => setDeleteModalState(!deleteModalState)}
        title={`Tem certeza que deseja excluir o link para essa cobrança pontual?`}
        messageBody="Excluí-lo desativará o link para futuras contratações."
        closeButtonText="Não"
        confirmButtonText="Sim"
        leastDestructiveRef={cancelRef}
      />
      {viewSplit && (
        <SubSellerModal
          isOpen={splitModalSubseller}
          headerText="Revise os valores do split"
          totalValue={value}
          paymentLinkValue={watch("value") ?? "0"}
          subSellerList={subSellersData}
          confirmButtonText="Criar link"
          closeButtonText="Voltar"
          subSellerTaxResponse={subSellerTaxResponse}
          barteTaxValue={previewData?.creditCardTax === "EARLY_SELLER" ? formatCurrency({ value: (formatCurrencyToApi(watch("value")) - previewData?.arrayInstallments?.installmentsToSeller[previewData?.arrayInstallments?.installmentsToSeller?.length - 1]?.receivedAmount) ?? 0 }) : formatCurrency({ value: (formatCurrencyToApi(watch("value")) - previewData?.arrayInstallments?.installmentsToSeller[0]?.receivedAmount) ?? 0 })}
          onClose={() => setSplitModalSubseller(false)}
          onConfirm={() => handleCreatePaymentOrder(values)}
          asSubmitModal
        />
      )}
      {/* {asEdition && (
        <Flex
          flexDir="column"
          maxW="520px"
          w="520px"
          margin="0 auto"
          marginTop="1.5rem"
        >
          <Stack spacing="4">
            <Flex align="center" justify="space-between">
              <Heading>Cobrança pontual</Heading>
              <Image
                id="excluir_cobranca"
                onClick={() => setDeleteModalState(!deleteModalState)}
                marginRight=".7rem"
                cursor="pointer"
                src="/images/icons/trash-delete.svg"
                transition="filter .2s"
                _hover={{
                  transition: "filter .2s",
                  filter: "brightness(.3)",
                }}
              />
            </Flex>
            <UrlToClipBoard text={applicationName === "payform" ? `${import.meta.env.VITE_CHECKOUT_URL}payment-link/${initialData?.uuid}` : url} />
          </Stack>
        </Flex>
      )} */}

      <Flex
        as="form"
        w="100%"
        flexDir="column"
        onSubmit={handleSubmit(handleCreatePaymentOrder)}
        autoComplete="off"
      >
        <NewContainer
          type="check"
          w="100%"
          title="Informações gerais"
          startsOpen
          isOk={value !== "" && title !== ""}
          p="25px"
        >
          {!asEdition &&

            <Select
              w="100%"
              name="Tipo de cobrança"
              label="Tipo de cobrança"
              defaultValue="Cobrança pontual"
              tooltipText="A cobrança pontual, seja parcelada ou à vista é utilizada para uma venda única, enquanto a cobrança recorrente é normalmente utilizada para serviços recorrentes, como uma assinatura."
              onChange={(e) => {
                setPunctualButton(e.target.value === "Pontual");
              }}
              optionsList={options}
            />
          }


          <Input
            label="Título"
            type="text"
            placeholder="Nome do link"
            name="title"
            register={{ ...register("title") }}
            onChange={(e) => {
              handleChange("title", e.target.value)
            }}
            errors={errors?.title}
          />
          <TextArea
            label="Descrição (Opcional)"
            name="description"
            placeholder="Descrição do link"

            color="black"
            labelFontSize=".8rem"
            labelColor="gray.600"
            maxLength={300}
            register={{ ...register("description") }}
            currentLength={optionalDescription?.length}
          />
          {/* <Input
            mask="currency"
            label="Valor"
            placeholder="Valor"
            type="text"
            name="value"
            register={{ ...register("value") }}
            onChange={(e) => {
              handleChange("value", e.target.value)
            }}
            errors={errors?.value} 
          /> */}

          <InputCurrency
            label="Valor"
            setValue={setValue}
            name={"value"}
            maxLength={10}
            register={{ ...register("value") }}
            onChange={(e) => {
              handleChange("value", e.target.value)
            }}
            placeholder="Valor"
          />


          <InputSearch
            name="clients"
            label="Clientes"
            placeholder="Nome do cliente"
            initialClient={initialData?.sellerClient}
            clients={clientList}
            setClients={setClientList}
            selectedClient={selectedClient}
            setSelectedClient={setSelectedClient}
          />
        </NewContainer>
        <Center w='100%' >
          <Divider orientation='horizontal' />
        </Center>

        <NewContainer
          w="100%"
          type="check"
          title="Métodos de pagamento"
          isOk={previewData?.enableBankSlip || previewData?.enableCreditCard || previewData?.enablePix}
          p="25px"
        >
          <PaymentMethod
            setIsOk={setIsOk}
            initialData={initialData}
            control={control}
            onCreation={!asEdition}
            hasInstallmentMix={Boolean(hasTagMix)}
            creditCardType={creditCardType}
            setCreditCardType={setCreditCardType}
            register={register}
            data={user.idSeller === 4483 ? paymentMethodsLumini : paymentMethods}
            errors={errors?.paymentMethods}
            watch={watch}
            setValue={setValue}
            anchoringState={anchoringState}
            isPunctualCharge={true}
            setAnchoringState={setAnchoringState}
            dateSwitchState={dateSwitchState}
            setDateSwitchState={setDateSwitchState}
            split={split}
          />
        </NewContainer>
        <Center w='100%' >
          <Divider orientation='horizontal' />
        </Center>
        <NewContainer
          w="100%"
          title="Configurações avançadas"
          type="retract"
          p="15px 25px 15px 0"
        >

          <TimeDatePickerInput
            timerState={timerState}
            setTimerState={setTimerState}
            startEnabled
            label="Expirar Link"
            name="timer"
            register={{ ...register("timer") }}
            hasSwitch
            control={control}
            disabledState={expirationSwitchState}
            setDisabledState={setExpirationSwitchState}
            errors={errors?.timer}
            setValue={setValue}
          />

          <AccordionTextArea
            name="bankSlipDescription"
            hasSwitch={bankSlipDescriptionState}
            register={{ ...register("bankSlipDescription") }}
            value={bankSlipDescriptionValue}
            errors={errors?.bankSlipDescription}
            asDoubleEffect
            switchFunction={(e) => setBankSlipDescriptionState(e.target.checked)}
          />


          {viewMetadados && (
            <CustomFields
              setMetadados={setMetadados}
              defaultMetadados={initialData?.metadata}
              hasSwitch
              setMetadadosDisabled={setMetadadosDisabled}
              name="Dados internos (metadados)"
              label="Dados internos (metadados)"
            />
          )}

          {viewSplit && (
            <PaymentSplit
              setSplit={setSplit}
              split={split}
              title={"Split de pagamento"}
              setSubSellersData={setSubSellersData}
              subSellersData={subSellersData}
              newClient={{} as subSellerData}
            />
          )}


        </NewContainer>
        <Center w='100%' >
          <Divider orientation='horizontal' />
        </Center>
        <Flex justifyContent="end">
          <Button
            type={split && viewSplit ? "button" : "submit"}
            isLoading={loading}
            alignSelf="center"
            onClick={() => {
              if (split && viewSplit) {
                handleSplitValues()
                setSplitModalSubseller(!splitModalSubseller)
              }
            }}
            variant="solid"
            bgColor="blue.200"
            color="white"
            borderRadius="3"
            margin="1rem 0"
            transition="filter .2s"
            _hover={{
              transition: "filter .2s",
              filter: "brightness(.9)",
            }}
            _active={{
              filter: "brightness(.6)",
            }}
            _focus={{}}
            disabled={!(value !== "" && title !== "") || !(enableBankSlip || enableCreditCard || enablePix)}

          >
            Criar cobrança
          </Button>
        </Flex>
      </Flex>
    </>
  );
}
