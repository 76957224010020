import { Flex, Box, Text, Divider, Tooltip } from "@chakra-ui/react";
import { Button, GhostButton } from "@/styles/components/Buttons";
import { InputCurrency } from "@/components";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { SetStateAction, useEffect, useState } from "react";
import { useTransfers } from "@/hooks/useTransfer";
import { formatCurrency, formatCurrencyToApi, showToast } from "@/utils";
import { Checkbox } from "../Forms/Checkbox";
import { useRedeem } from "@/hooks";
import { InfoIconNotFilled } from "@/styles/icons/InfoIconNotFilled";

const validationSchema = Yup.object().shape({
    amount: Yup.string(),
});

interface transfersProps {
    open?: React.Dispatch<SetStateAction<boolean>>
    data?: any;
}

type FormValues = {
    amount: string;
    useAnticipationAmount: boolean;
};

export default function NewTransfer({ open, data }: transfersProps) {
    const { requestTransfer, requestAnticipationTransfer } = useTransfers();
    const { getSellerAnticipationAmount, getSellerFraud } = useRedeem();
    const [anticipationValue, setAnticipationValue] = useState({} as any)
    const [useAnticipationAmount, setUseAnticipationAmount] = useState(false);
    const [isFraud, setIsFraud] = useState(false);
    const [isDisabledByHour, setIsDisabledByHour] = useState(false);
    const user = JSON.parse(localStorage.getItem("barte-user-info"));
    const featureToggle = JSON.parse(localStorage.getItem("barte-features-list-user"));
    const {
        register,
        handleSubmit,
        setValue,
        watch,
        formState: { errors },
    } = useForm<FormValues>({
        resolver: yupResolver(validationSchema),
    });
    const formatNumber = (currency) => {
        return parseFloat(currency.replace(/[^0-9,-]/g, '').replace(',', '.'));
    }
    const value = watch("amount");

    async function newTransfer(data?: any) {
        try {
            if(useAnticipationAmount) {
                await requestAnticipationTransfer();
                await new Promise(resolve => setTimeout(resolve, 1000))
                await requestTransfer({ amount: anticipationValue?.amountToReceive })
            } else {
                await requestTransfer({ amount: formatNumber(data?.amount) ?? 0 })
            }
            open(false)
            showToast({
                message: "Saque agendado com sucesso!",
                type: "success",
            });
        } catch (error) {
            open(false)
            if (error?.response?.data?.errors) {
                error?.response?.data?.errors.map((error) => {
                  showToast({
                    type: "error",
                    message: `${error?.code} - ${error?.description}`,
                  });
                });
            } else {
                showToast({
                  type: "error",
                  message: "Falha na requisição, por favor tente novamente mais tarde",
                });
            }
            return (error)
        }
    }

    useEffect(() => {
        const formattedValue = formatCurrencyToApi(value);
        getSellerAnticipationAmount({
            requestedAmount: isNaN(formattedValue) ? 0 : formattedValue,
            useAnticipationAmount: useAnticipationAmount
        })
        .then(res => setAnticipationValue(res.data))
        .catch(() => {})
        getSellerFraud(user?.idSeller)
        .then(res => setIsFraud(res?.data?.isFraud))
        .catch(() => {})
    }, [value, useAnticipationAmount])
    useEffect(() => {
        const timeValue = new Date().getUTCHours() - 3;
        if(timeValue >= 23 || timeValue < 1) {
            setIsDisabledByHour(true);
        }
        if(data?.notFormattedAmount < 0) {
            setValue("amount", data?.amount);
            return
        }
    } , [data])
    return (
        <Flex
            position="fixed"
            top="0"
            left="0"
            width="100%"
            height="100%"
            backgroundColor="#262F4080"
            zIndex="100"
            justifyContent="center"
            alignContent="center"
            alignItems="center"
        >
            <Flex
                width="374px"
                bgColor="white"
                borderRadius="8px"
                flexDirection="column"
                justifyContent="space-between"
                p="16px 16px 10px"
                color="black"
                as="form"
                onSubmit={handleSubmit(newTransfer)}
            >
                <Flex
                    gap="12px"
                    padding="0 8px"
                >
                    <img src="/images/icons/newWallet.svg" />

                    <Text
                        fontSize="25px"
                        fontWeight="600"
                    >
                        Novo repasse
                    </Text>
                </Flex>
                {isDisabledByHour && (
                    <Text
                        fontSize=".8rem"
                        textAlign="center"
                        backgroundColor="yellow.100"
                        borderRadius=".5rem"
                        p=".5rem"
                    >
                       A solitação de repasse fica bloqueada no período das 23:00h e 01:00h.
                    </Text>
                )}

                <Flex
                    padding="24px 8px"
                    flexDirection="column"
                    height="100%"
                    gap="12px"
                >
                    <Flex
                        flexDirection="column"
                    >
                        <Flex
                            backgroundColor="#F4F7FB"
                            padding="8px 16px"
                            gap="24px"
                            borderRadius="4px"
                        >
                            <Text>
                                Saldo disponível
                            </Text>
                            <Text>
                                <Text as="strong" >
                                    {data?.amount}
                                </Text>
                            </Text>
                        </Flex>
                        <InputCurrency
                            label="Valor" 
                            setValue={setValue}   
                            name="amount"
                            maxLength={10}
                            register={{ ...register("amount") }}
                            placeholder="Digite o valor a ser transferido"
                            aria-label={"Valor"}
                            aria-invalid={errors.amount ? "true" : "false"}
                            fontSize="1rem"
                            isDisabled={data?.notFormattedAmount === 0 || data?.notFormattedAmount < 0}
                            errors={errors?.amount}
                        />
                        <Flex
                            backgroundColor="#F4F7FB"
                            padding="8px 16px"
                            gap="24px"
                            borderRadius="4px"
                        >
                            <Checkbox 
                                size="md"
                                colorScheme="blue"
                                textSize=".8rem"
                                text="Incluir saldo a liberar"
                                isChecked={useAnticipationAmount}
                                onChange={(event) => setUseAnticipationAmount(event.currentTarget.checked)}
                                disabled={formatCurrencyToApi(data?.provisionedAmount) === 0 || !featureToggle?.includes("view_balance_anticipation")}
                            />
                            <Text
                                fontSize=".8rem"
                            >
                                {data?.amountToAnticipation}
                            </Text>
                        </Flex>
                    </Flex>
                </Flex>
                
                {anticipationValue && (
                <>
                    <Divider />
                    <Flex
                        w="100%"
                        flexDir="column"
                        mt="10px"
                        mb="10px"
                        gap="12px"
                    >
                        <Flex justifyContent="space-between">
                            <Flex
                                align="center"
                                gap="8px"
                            >
                                <Tooltip 
                                    label="Soma do Valor solicitado com o Saldo a liberar, se selecionado"
                                    zIndex="150"
                                >
                                    <Flex 
                                        align="center"
                                        gap="4px"
                                    >
                                        <Text 
                                            fontSize=".8rem"
                                        >
                                            Total solicitado
                                        </Text>
                                        
                                        <InfoIconNotFilled size={10} />
                                    </Flex>
                                </Tooltip>
                            </Flex>
                            <Text
                                fontSize=".8rem"
                            >
                                {formatCurrency({value: anticipationValue?.totalRedeem})}
                            </Text>
                        </Flex>
                        <Flex justifyContent="space-between">
                            <Text 
                                fontSize=".8rem"
                            >
                                Taxa de antecipação
                            </Text>
                            <Text 
                                fontSize=".8rem"
                            >
                                {isFraud && useAnticipationAmount ? formatCurrency({ value: anticipationValue?.totalRedeem * 0.10 }) : formatCurrency({value: anticipationValue?.anticipationTax})}
                            </Text>
                        </Flex>
                        <Flex justifyContent="space-between">
                            <Text 
                                as="strong" 
                                fontSize=".8rem"
                            >
                                Você recebe
                            </Text>
                            <Text
                                as="strong"
                                fontSize=".8rem"
                            >
                                {isFraud && useAnticipationAmount ? formatCurrency({ value: anticipationValue?.totalRedeem - (anticipationValue?.totalRedeem * 0.10) }) : formatCurrency({value: anticipationValue?.amountToReceive})}
                            </Text>
                        </Flex>
                    </Flex>
                </>
                )}
                <Divider />
                <Flex
                    justifyContent="end"
                    gap="8px"
                    mt="10px"
                >
                    <Box
                        width="110px"
                        height="22px"
                    >
                        <GhostButton onClick={() => open(false)}>Cancelar</GhostButton>
                    </Box>
                    <Box>
                        <Button 
                            type="submit"
                            disabled={formatCurrencyToApi(value) <= 9 && !useAnticipationAmount || formatCurrencyToApi(value) > data?.notFormattedAmount || isDisabledByHour}
                        >
                            Solicitar saque
                        </Button>
                    </Box>
                </Flex>

            </Flex>
        </Flex>

    )
}